<ui-modal modalTitle="Formula Help" [scrollable]="true">
	<div class="d-flex" ui-modal-sticky-header>
		<div *ngFor="let t of helpTabs" class="px-3 py-4 flex-weight-1 selectable hoverable border-bottom d-flex align-items-center" [ngClass]="{ 'border-primary border-4': helpTab === t.id, 'border-lightgrey': helpTab !== t.id }" (click)="helpTab = t.id">
			<span class="text-uppercase text-small text-bold mr-2" [ngClass]="{ 'text-secondary': helpTab !== t.id }">
				{{t.description}}
			</span>
		</div>
	</div>

	<ng-container *ngIf="helpTab === 'variables'">
		<p>You can use any of the following variables in your calculations:</p>
		<table class="table table-sm table-hover">
			<thead>
				<tr>
					<th>Name</th>
					<th>Description</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td class="text-bold w-7r">ProductNet</td>
					<td>The ex {{app.translatedVATTerm}} subtotal of all products (without labour).</td>
				</tr>
				<tr>
					<td class="text-bold">ProductVat</td>
					<td>The total {{app.translatedVATTerm}} amount of all products (without labour).</td>
				</tr>
				<tr>
					<td class="text-bold">ProductTotal</td>
					<td>The inc {{app.translatedVATTerm}} total of all products (without labour).</td>
				</tr>

				<tr>
					<td class="text-bold">LabourHours</td>
					<td>The total number of labour hours on the quote.</td>
				</tr>
				<tr>
					<td class="text-bold">LabourNet</td>
					<td>The ex {{app.translatedVATTerm}} subtotal of all labour.</td>
				</tr>
				<tr>
					<td class="text-bold">LabourVat</td>
					<td>The total {{app.translatedVATTerm}} amount of all labour.</td>
				</tr>
				<tr>
					<td class="text-bold">LabourTotal</td>
					<td>The inc {{app.translatedVATTerm}} total of all labour.</td>
				</tr>

				<tr>
					<td class="text-bold">QuoteNet</td>
					<td>The ex {{app.translatedVATTerm}} subtotal of all products and labour.</td>
				</tr>
				<tr>
					<td class="text-bold">QuoteVat</td>
					<td>The total {{app.translatedVATTerm}} amount of all products and labour.</td>
				</tr>
				<tr>
					<td class="text-bold">QuoteTotal</td>
					<td>The inc {{app.translatedVATTerm}} total of all products and labour.</td>
				</tr>
			</tbody>
		</table>
		<p>The following are also available for <b>product and quote line factors</b>:</p>
		<table class="table table-sm table-hover">
			<thead>
				<tr>
					<th>Name</th>
					<th>Description</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td class="text-bold w-7r">UnitCost</td>
					<td>The item's original net unit cost price (after price adjustments).</td>
				</tr>
				<tr>
					<td class="text-bold">UnitSell</td>
					<td>The item's original net unit sell price (after price adjustments).</td>
				</tr>
				<tr>
					<td class="text-bold">Quantity</td>
					<td>The item's total line quantity.</td>
				</tr>
				<tr>
					<td class="text-bold">ParentUnitCost</td>
					<td><i>Accessories only.</i> The main item's original net unit cost price (after price adjustments).</td>
				</tr>
				<tr>
					<td class="text-bold">ParentUnitSell</td>
					<td><i>Accessories only.</i> The main item's original net unit sell price (after price adjustments).</td>
				</tr>
				<tr>
					<td class="text-bold">ParentQuantity</td>
					<td><i>Accessories only.</i> The main item's total line quantity.</td>
				</tr>
			</tbody>
		</table>
	</ng-container>

	<ng-container *ngIf="helpTab === 'operators'">
		<p>You can use the following operators:</p>
		<table class="table table-sm table-hover">
			<thead>
				<tr>
					<th>Example</th>
					<th>Description</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td class="text-bold nowrap">x + y</td>
					<td>Addition</td>
				</tr>
				<tr>
					<td class="text-bold nowrap">x - y</td>
					<td>Subtraction</td>
				</tr>
				<tr>
					<td class="text-bold nowrap">x * y</td>
					<td>Multiplication</td>
				</tr>
				<tr>
					<td class="text-bold nowrap">x / y</td>
					<td>Division</td>
				</tr>
				<tr>
					<td class="text-bold">x ^ y</td>
					<td>Raise x to the power of y</td>
				</tr>
				<tr>
					<td class="text-bold">x % y</td>
					<td>Modulo (remainder of x / y)</td>
				</tr>
			</tbody>
		</table>
		<p>In addition to simple arithmetic, the following functions are also available:</p>
		<table class="table table-sm table-hover">
			<thead>
				<tr>
					<th>Example</th>
					<th>Description</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td class="text-bold nowrap">min(x1, x2, ...)</td>
					<td>Evaluates to the smallest argument.</td>
				</tr>
				<tr>
					<td class="text-bold nowrap">max(x1, x2, ...)</td>
					<td>Evaluates to the largest argument.</td>
				</tr>
				<tr>
					<td class="text-bold nowrap">sum(x1, x2, ...)</td>
					<td>Evaluates to the total of all arguments.</td>
				</tr>
				<tr>
					<td class="text-bold nowrap">avg(x1, x2, ...)</td>
					<td>Evaluates to the average of all arguments.</td>
				</tr>
				<tr>
					<td class="text-bold nowrap">round(x, d)</td>
					<td>Rounds the argument x to d decimal places. Argument d is optional, defaults to 0.</td>
				</tr>
				<tr>
					<td class="text-bold nowrap">abs(x)</td>
					<td>Evaluates to the absolute value of x.</td>
				</tr>
				<tr>
					<td class="text-bold nowrap">log(x, b)</td>
					<td>Evaluates to the base b logarithm of x. Argument b is optional, defaults to natural logarithm.</td>
				</tr>
				<tr>
					<td class="text-bold nowrap">sqrt(x)</td>
					<td>Evaluates to the square root of x.</td>
				</tr>
			</tbody>
		</table>
	</ng-container>

	<ng-container *ngIf="helpTab === 'advanced'">
		<p>You can define your own temporary variables to make formulas more concise or more readable. Simply assign the variables with the equals sign and end the expression with a semicolon. The formula's final value will be the last section without an assignment. Example formula:</p>
		<p class="text-bold">x = 1 + 1; y = 2 ^ 3; x * y</p>
		<p>First, the value of (1 + 1) is assigned to variable x, which now has the value of 2.</p>
		<p>Next, the value of (2 ^ 3) is assigned to variable y, which now has the value of 8 (2 to the power of 3).</p>
		<p>Finally, (x * y) is evaluated as (2 * 8) after substituting the value of the variables, so the final result of the formula is 16.</p>
	</ng-container>

	<button type="button" class="btn btn-secondary" (click)="modal.close()" ui-modal-footer>Close</button>
</ui-modal>
